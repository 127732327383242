<template>
    <content-loader :height="400" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="55" y="103" rx="5" ry="5" width="260" height="15" />
        <rect x="55" y="155" rx="5" ry="5" width="260" height="15" />
        <rect x="55" y="207" rx="5" ry="5" width="260" height="15" />
        <rect x="70" y="250" rx="5" ry="5" width="200" height="45" />
        <rect x="30" y="15" rx="2" ry="2" width="68" height="60" />
    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
