<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- job section -->
            <section class="job-section section-gap">
                <div class="section-title-wrap"><h2 class="section-title text-capitalize">{{ $t("Applied Jobs")}}</h2></div>
                <div class="row row-cols-md-4" v-if="isContentLoading">
                    <div class="col" v-for="index in 4" :key="index">
                        <CandidateJobLoader></CandidateJobLoader>
                    </div>
                </div>
                <template v-else>
                    <h4 class="empty-message" v-if="isEmpty">{{ $t("no jobs found")}}</h4>
                    <div class="row row-cols-md-4" v-else>
                        <div class="col" v-for="job in appliedJobs">
                            <div class="job-card">
                                <div class="d-flex align-items-center">
                                    <div class="job-icon"><i class="eicon e-briefcase"></i></div>
                                    <template v-if="job.currentPipeline">
                                        <div class="text-capitalize"
                                             :class="{
                                            'danger-label' : job.currentPipeline.rejected,
                                            'success-label' : job.currentPipeline.selected,
                                            'primary-label' : job.currentPipeline.other}"
                                        >{{job.currentPipeline.name || $t('undefined')}}
                                        </div>
                                    </template>
                                </div>
                                <a :href="job.url" target="_blank" class="job-title" v-text="job.title"></a>
                                <ul>
                                    <li><span>{{job.companyName}}</span>, <span>{{job.location}}</span></li>
                                    <li>
                                        <span>{{ $t("Applied Date")}}</span>:
                                        <label v-html="dateConvertToSpan(job.applyDate)"></label>
                                    </li>
                                    <li v-if="job.vacancy>0">{{ $t("No of vacancies")}}: {{job.vacancy}}</li>
                                </ul>
                                <div class="">
                                    <a :href="job.url" target="_blank" class="button semi-button-info pull-right">{{ $t("See details job post")}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination v-if="!isEmpty" :pagination="paginationData" @paginate="paginateTo"></pagination>
                </template>

            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    import DashboardLayout from "../../layouts/DashboardLayout";
    import client from "../../app/api/Client";
    import CandidateJobLoader from "../../components/_loaders/CandidateJobLoader";
    import Pagination from "../../components/pagination/Pagination";
    import { dateConvertSpan } from "../../extra/helper";

    export default {
        data() {
            return {
                isContentLoading: false,
                appliedJobs: [],
                paginationData: null,
                form : {
                    page: 1
                }
            }
        },
        components: {
            DashboardLayout,
            CandidateJobLoader,
            Pagination
        },
        methods: {
            async paginateTo(page) {
                if (typeof page === 'undefined') page = 1;
                this.form.page = page;
                await this.getAppliedJobs();
            },
            async getAppliedJobs() {
                this.isContentLoading = true;
                try {
                    let {data: {data}} = await client().get('/candidate/applied-job', {
                        "params":  this.form
                    });
                    this.appliedJobs = data.data;
                    this.paginationData = data;
                } catch (e) {
                }

                this.isContentLoading = false;
            },
            dateConvertToSpan(date) {
                return dateConvertSpan(date);
            }
        },
        computed: {
            isEmpty() {
                return this.appliedJobs.length === 0;
            }
        },
        created() {
            this.getAppliedJobs();
        }
    }
</script>

<style scoped>
.job-section .job-card ul {
    list-style: none;
}
</style>